<template>
  <span
    >{{ displayName }}{{ flags ? " " : ""
    }}<PersonFlags v-if="flags" :value="value"
  /></span>
</template>
<script>
const ADDRESS = {
  F: "Frau ",
  M: "Herr ",
};
import { personName } from "common/utils/people.js";
import PersonFlags from "common/components/PersonFlags.vue";
export default {
  name: "PersonName",
  components: { PersonFlags },
  props: {
    value: { type: Object },
    address: { type: Boolean, default: false },
    ext: { type: Boolean, default: false },
    title: { type: Boolean, default: false },
    flags: { type: Boolean, default: false },
  },

  computed: {
    displayName() {
      if (!this.value || !this.value.id) {
        return "";
      }
      let result = personName(this.value);

      if (this.ext && this.value.schoolClass) {
        result += " (" + this.value.schoolClass.code + ")";
      }
      if (this.ext && this.value.code) {
        result += " (" + this.value.code + ")";
      }

      if (this.title && this.value.title) {
        result = this.value.title + " " + result;
      }
      if (this.address && this.value.gender) {
        result = ADDRESS[this.value.gender.code] + result;
      }
      return result;
    },
  },
};
</script>
<style scoped>
span {
  overflow-wrap: normal;
  word-break: normal;
}
</style>
