<template>
  <span>
    <v-tooltip
      top
      v-for="flag in value.flags"
      :key="'flag' + value.id + flag.code"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on" right>{{
          personFlagIcon(flag.code)
        }}</v-icon>
      </template>
      <span>{{ flag.description }} </span>
    </v-tooltip>
  </span>
</template>
<script>
import { personFlagIcon } from "common/utils/icons.js";
export default {
  name: "PersonFlags",
  props: {
    value: { type: Object },
  },
  methods: {
    personFlagIcon,
  },
};
</script>
